exports.components = {
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-not-found-tsx": () => import("./../../../src/pages/NotFound.tsx" /* webpackChunkName: "component---src-pages-not-found-tsx" */),
  "component---src-pages-privacy-policies-tsx": () => import("./../../../src/pages/privacy-policies.tsx" /* webpackChunkName: "component---src-pages-privacy-policies-tsx" */),
  "component---src-pages-team-details-tsx": () => import("./../../../src/pages/team-details.tsx" /* webpackChunkName: "component---src-pages-team-details-tsx" */),
  "component---src-pages-team-member-tsx": () => import("./../../../src/pages/team-member.tsx" /* webpackChunkName: "component---src-pages-team-member-tsx" */)
}

